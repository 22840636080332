<script setup>
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, reactive } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import ImageUploader from "@/components/products/ImageUploader.vue";

const { fetchProduct, product, productImages, fetchProductImages } = useProducts();

const route = useRoute();
const imgDetails = reactive([
  {
    id: null,
    imgType: "individual",
    label: "Individual Product",
    title: "Add Individual Product Image",
    imgUrl: null,
  },
  {
    id: null,
    imgType: "individual_label",
    label: "Product Label",
    title: "Add Product Label Image",
    imgUrl: null,
  },
  {
    id: null,
    imgType: "case",
    label: "Case",
    title: "Add Case Image",
    imgUrl: null,
  },
  {
    id: null,
    imgType: "case_label",
    label: "Case Label",
    title: "Add Case Label Image",
    imgUrl: null,
  },
  {
    id: null,
    imgType: "pallet",
    label: "Pallet",
    title: "Add Pallet Image",
    imgUrl: null,
  },
  {
    id: null,
    imgType: "pallet_label",
    label: "Pallet Label",
    title: "Add Pallet Label Image",
    imgUrl: null,
  },
]);

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  await fetchProductImages(route.params.id);
  mapUrlFromResponse();
  setPageTitle(`Product -${product.value.id}`);
});

const mapUrlFromResponse = () => {
  imgDetails.forEach((imgDetail) => {
    const filteredProductImg = productImages.value.filter(
      (img) => img.image_type == imgDetail.imgType && !img.deleted_at
    );
    if (filteredProductImg.length > 0) {
      imgDetail.imgUrl = filteredProductImg[0].url;
      imgDetail.id = filteredProductImg[0].id
    }
  });
};
</script>

<template>
  <div id="grid-wrapper">
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4>Product Images</h4>
      </div>
      <div class="card-wrapper">
        <v-card class="w-100 pa-4">
          <v-row align="center" class="font-weight-bold" justify="space-between">
            <ImageUploader v-for="imgDetail in imgDetails" :imgDetail="imgDetail" />
          </v-row>
        </v-card>
      </div>
    </ProductLayout>
  </div>
</template>

<style scoped>
.card-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
