<script setup>
import { ref } from "vue";
import WarningDialog from "../common/WarningDialog.vue";
import c3api from "@/c3api";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const dialog = ref(false);
const isLoading = ref(false);
const route = useRoute();
const toast = useToast();

const WARNING_TITLE = "Delete Confirmation";

const props = defineProps({
    imgDetail: {
        type: Object,
        required: true
    }
})

const payload = {
    message: `Are you sure you want to delete the ${props.imgDetail.label} image?`,
    color: "red",
}

const handleAddImage = async (e) => {
    isLoading.value = true;
    const img = e.target.files[0];
    const formData = new FormData;

    formData.append("file", img);
    formData.append("image_type", props.imgDetail.imgType);
    try {
        const res = await c3api.post(`/products/${route.params.id}/product_images`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        isLoading.value = false
        props.imgDetail.imgUrl = res.data.data.url;
        props.imgDetail.id = res.data.data.id;
        toast.success("Image uploaded successfully.");
    }catch(err) {
        isLoading.value = false
        toast.error(err.response.data.data?.join(". "));
    }
}

const handleDelete = async (deleteImg) => {
    dialog.value = false
    if(deleteImg){
        isLoading.value = true
        const urlParams = {
            image_id: props.imgDetail.id
        };
        try {
            const res = await c3api.delete(`/products/${route.params.id}/product_images`, { params:  urlParams })
            props.imgDetail.imgUrl = null;
            props.imgDetail.id = null;
            isLoading.value = false
            toast.success("Image deleted successfully.")
        }catch(err) {
            isLoading.value = false
            toast.error(err.response.data.data.join(". "));
        }
    }
}
</script>

<template>
    <v-col cols="6">
        <label>{{props.imgDetail.label}}</label>
    </v-col>
    <v-col class="d-flex justify-end" cols="6">
        <WarningDialog v-if="dialog" :callback="handleDelete" :msg="payload" :title="WARNING_TITLE"/>
        <v-btn :loading="isLoading" v-if="props.imgDetail.imgUrl" color="error" @click="dialog = !dialog">Delete {{props.imgDetail.title}} 
            <template v-slot:loader>
                <v-progress-circular
                indeterminate
                > </v-progress-circular> Deleting...
            </template></v-btn>
        <v-btn :loading="isLoading" v-else color="primary" @click="$refs.btnRef.click()">{{props.imgDetail.title}} 
            <template v-slot:loader>
                <v-progress-circular
                indeterminate
                > </v-progress-circular> Uploading...
            </template>
        </v-btn>
        <input v-show="false" type="file" ref="btnRef" @change="handleAddImage"/>
    </v-col>
    <v-col v-if="props.imgDetail.imgUrl" cols="12">
        <v-img
        :max-width="300"
        :max-height="300"
        :src="props.imgDetail.imgUrl"
        >
        <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                ></v-progress-circular>
            </div>
        </template>
        </v-img>
    </v-col>
    <v-divider v-if="props.imgDetail.label != 'Pallet Label'" />
</template>